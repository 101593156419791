import styled from "@emotion/styled"
import mq from "../lib/mq"

const px = [24, 48, 64]
const py = [82, 160, 190]

export default styled.section`
  margin: 0 auto;
  position: relative;

  ${mq({
    maxWidth: [600, 900, 1220],
    padding: px.map((_, i) => `${py[i]}px ${px[i]}px`),
  })}

  &:not(:first-of-type):before {
    content: "↓";
    // content: "👇";
    font-size: 24px;
    position: absolute;
    top: 0px;
    // margin: 0 auto;
    text-align: center;
    left: 0;
    ${mq({
      left: px.map((_, i) => `${px[i]}px`),
    })}
    // right: 0;
    color: #f2b705;
    transform: translateY(-50%);
  }

  // &:nth-of-type(even):before {
  //   content: "";
  //   position: absolute;
  //   top: 5%;
  //   bottom: 5%;
  //   width: 100vw;
  //   left: 50%;
  //   margin-left: -50vw;
  //   z-index: -1;
  //   background-color: #024959;
  //   transform: perspective(60px) rotateY(0.3deg) scale(1.1);
  //   box-shadow: 0 0 150px 0px rgba(0, 0, 0, 0.1);
  // }

  // &:last-of-type:after {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   height: 100%;
  //   left: 50%;
  //   margin-left: -50vw;
  //   width: 100vw;
  //   z-index: -1;
  //   background-color: #024959;
  // }
`
