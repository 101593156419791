import styled from "@emotion/styled"
import mq from "../lib/mq"

export default styled.p`
  line-height: 1.5;
  color: ${props => props.color || "#9ab4ba"};
  padding-top: 12px;
  break-inside: avoid-column;

  ${props =>
    props.small
      ? mq({
          fontSize: [14, 16, 19],
        })
      : mq({
          fontSize: [18, 24, 28],
        })}

  strong {
    display: block;
    font-weight: 900;
    color: #fff;
  }
`
