import styled from "@emotion/styled"
import mq from "../lib/mq"

export default styled.h2`
  ${mq({
    fontSize: [24, 48, 64],
    paddingTop: [24, 48, 80],
  })}
  line-height: 1.4;
  font-weight: 900;

  small {
    font-weight: 200;
    ${mq({
      display: ["inline", "inline", "inline-block"],
    })}
  }
`
